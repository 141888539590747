<template>
  <teleport to="body">
    <div class="modal-container"
         :class="[{'active': opened}, className]"
         :style="{visibility: opened ? 'visible' : 'hidden'}">
      <div v-if="opened"
           class="backdrop"
           :class="{transparent: transparentBg}"
           v-on="closeEnabled ? {click: () => closeModal()}: {}"></div>
      <transition name="modal">
        <div class="modal"
             :class="{immutable: immutableWidth}"
             v-if="opened"
             :style="{maxWidth: maxWidth + 'px', maxHeight: maxHeight}">
          <slot name="title">
            <header :class="{'no-paddings':!paddings}">
              <slot name="name"></slot>
              <svg-icon v-on="closeEnabled ? {click: () => closeModal()}: {}"
                        icon="close"/>
            </header>
          </slot>
          <section class="main" :class="{'scroll':scroll, 'no-paddings':!paddings}">
            <slot></slot>
            <slot name="footer-action"></slot>
          </section>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "Modal",
  props: {
    opened: {
      type: Boolean
    },
    maxWidth: {
      type: Number,
      default: 578
    },
    maxHeight: {
      type: String,
      default: '95vh',
    },
    className: {
      type: String,
    },
    immutableWidth: {
      type: Boolean,
      required: false,
    },
    closeEnabled: {
      type: Boolean,
      default: true,
    },
    transparentBg: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    paddings: {
      type: Boolean,
      default: true
    }
  },
  emits: ['closeModal'],
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  },
  watch: {
    opened(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped lang="scss">
section.main.scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: 1px solid var(--scroll-bar-screen);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-screen);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-bar-screen);
  }
}
section.main.no-paddings {
  padding: 0;
}
header.no-paddings {
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>